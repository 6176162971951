
















































import { Component, Prop, Vue } from "vue-property-decorator";
import NotificationsMenu from "@/components/notifications/NotificationsMenu.vue";
import { State } from "vuex-class";
import User from "@/entity/User";

@Component({
  components: {
    NotificationsMenu,
  },
})
export default class AppBar extends Vue {
  @Prop({ default: true, type: Boolean }) isDrawerOpen!: boolean;
  @State("user", { namespace: "auth" }) loggedInUser!: User;

  private toggleDrawer(): void {
    this.$emit("update:isDrawerOpen", !this.isDrawerOpen);
  }

  nameInitials(name: string): string {
    const [firstName, lastName] = name.split(" ");
    return `${firstName[0]}${lastName ? lastName[0] : ""}`.toUpperCase();
  }

  logout(): void {
    // Can't be done via router, because back-end guards this route
    window.location.replace("/logout");
  }
}
